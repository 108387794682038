:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

.custom-dropdown {
  position: relative;
}

.custom-dropdown .dropdown-toggle {
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.custom-dropdown .dropdown-menu {
  position: absolute;
  max-height: 250px;
  overflow-y: scroll;
  z-index: 1060;
  top: calc(100% + 10px);
  width: 100%;
  margin-top: 0;
}

.custom-dropdown .dropdown-menu .form-control {
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  color: #ffffff;
}

.custom-dropdown .dropdown-menu .form-control:focus {
  background-color: #3f4156;
  border-color: #7c3aed;
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
}

/* Remove the nested scrollable container */
.custom-dropdown .dropdown-menu ul {
  /* max-height: none; Remove the max-height */
  overflow-y: visible; /* Remove the overflow */
}

.custom-dropdown .dropdown-menu.show {
  display: block;
}

.custom-dropdown .career-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  font-size: 18px;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.custom-dropdown .career-dropdown-item:hover,
.custom-dropdown .career-dropdown-item:focus,
.custom-dropdown .career-dropdown-item.active {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.modal-content.dark-xl {
  background-color: #1a1b26;
  color: #ffffff;
}

.juree-tab .nav-tabs {
  border: none;
  background-color: #2f3142;
  border-radius: 30px;
  padding: 5px;
}

.juree-tab .nav-link {
  border: none;
  color: #ffffff;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.juree-tab .nav-link.active {
  background-color: #7c3aed;
  color: #ffffff;
}

.custom-dropdown .dropdown-toggle {
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  color: #ffffff;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 10px;
}

.custom-dropdown .dropdown-menu {
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  border-radius: 10px;
  margin-top: 5px;
}

.custom-dropdown .career-dropdown-item {
  color: #ffffff;
  padding: 10px 20px;
}

.custom-dropdown .career-dropdown-item:hover,
.custom-dropdown .career-dropdown-item:focus,
.custom-dropdown .career-dropdown-item.active {
  background-color: #3f4156;
}

.btn-default.btn-large {
  background-color: #7c3aed;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.btn-default.btn-large:hover {
  background-color: #6d28d9;
}

.close-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.forum-container {
  max-width: 800px;
  margin: 0 auto;
}

.forum-category-card,
.forum-post-card {
  margin-bottom: 20px;
  border: 2px solid #5e5e5e;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.forum-category-card:hover,
.forum-post-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.category-link,
.post-link {
  text-decoration: none;
  color: inherit;
}

.category-content,
.post-content {
  display: flex;
  align-items: center;
  padding: 20px;
}

.category-icon,
.post-icon {
  margin-right: 20px;
}

.category-info,
.post-info {
  flex-grow: 1;
}

.category-title {
  margin: 0 0 10px;
  font-size: 1.75rem;
}

.category-description,
.post-excerpt {
  margin: 0 0 10px;
  font-size: 1.5rem;
  color: #6c757d;
}

.category-stats,
.post-stats {
  font-size: 1.5rem;
  color: #6c757d;
}

.category-stats span,
.post-stats span {
  margin-right: 15px;
}

.category-arrow,
.post-arrow {
  margin-left: 20px;
}

.author-avatar {
  border-radius: 50%;
}

.post-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.post-details-card {
  background-color: #000000;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.post-author {
  display: flex;
  align-items: center;
}

.author-avatar {
  border-radius: 50%;
  margin-right: 12px;
}

.author-info {
  display: flex;
  flex-direction: column;
}

.author-name {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
}

.post-date {
  font-size: 0.9rem;
  color: #6c757d;
}

.post-stats {
  font-size: 0.9rem;
  color: #6c757d;
}


.post-excerpt {
  font-size: 1.5rem; /* Increased from 1.1rem */
  color: #b8b9c3;
  margin-bottom: 20px; /* Increased from 16px */
  line-height: 1.6;
}

.post-stats {
  font-size: 1rem; /* Increased from 0.9rem */
  color: #9899ac;
}

.post-content {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 0;
}

.comments-title {
  font-size: 1.75rem; /* Increased from 1.5rem */
  margin-top: 40px; /* Increased from 32px */
  margin-bottom: 28px; /* Increased from 24px */
  color: #ffffff;
  border-bottom: 2px solid #4a4b65;
  padding-bottom: 10px; /* Increased from 8px */
}

.comment-list-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.post-details-card {
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.post-details-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.post-info {
  flex-grow: 1;
}

.post-title {
  font-size: 2.25rem; /* Increased from 2rem */
  margin-bottom: 16px; /* Increased from 12px */
  color: #ffffff;
}

.post-excerpt {
  font-size: 1.5rem; /* Increased from 1.1rem */
  color: #b8b9c3;
  margin-bottom: 20px; /* Increased from 16px */
  line-height: 1.6;
}

.post-stats {
  font-size: 1.5rem; /* Increased from 0.9rem */
  color: #9899ac;
}

.post-stats span {
  margin-right: 15px;
}

.post-icon {
  margin-left: 20px;
}

.author-avatar {
  border-radius: 50%;
  border: 2px solid #ba23d1;
}

.comments-title {
  font-size: 2rem; /* Increased from 1.5rem */
  margin-top: 40px; /* Increased from 32px */
  margin-bottom: 28px; /* Increased from 24px */
  color: #ffffff;
  border-bottom: 2px solid #4a4b65;
  padding-bottom: 10px; /* Increased from 8px */
}

.forum-category-card {
  background-color: #070707;
  border: 1px solid #4a4b65;
  border-radius: 12px;

  margin-bottom: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.forum-category-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.forum-comment-card {
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  border-radius: 12px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.forum-comment-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}


.category-content {
  display: flex;
  align-items: flex-start;
  padding: 20px;
}

.category-icon {
  margin-right: 20px;
}

.category-info {
  flex-grow: 1;
}

.comment-content {
  font-size: 1.5rem; /* Increased from 1rem */
  color: #ffffff;
  margin-bottom: 16px; /* Increased from 12px */
  line-height: 1.6;
}

.category-stats {
  font-size: 1.25rem;
  color: #9899ac;
}

.category-stats span {
  margin-right: 15px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pagination-button {
  background-color: #2f3142;
  color: #ffffff;
  border: 1px solid #4a4b65;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #3f4156;
}

.pagination-button:disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 1.5rem;
  color: #9899ac;
  margin: 0 1.5rem;
}

.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 28px;
  padding-bottom: 10px;
  border-bottom: 2px solid #4a4b65;
}

.comments-title {
  font-size: 2rem;
  color: #ffffff;
  margin: 0;
  border-bottom: 0px solid #1b1d5e;
}

.btn-section {
  display: flex;
  align-items: center;
}

.btn-default.bg-solid-primary {
  background-color: #7c3aed;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 1.5srem;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}

.btn-default.bg-solid-primary:hover {
  background-color: #6d28d9;
  cursor: pointer;
}

.btn-default.bg-solid-primary .icon {
  margin-right: 8px;
}

.example-questions {
  max-width: 800px;
  margin-top: 2rem;
}

.example-questions h6 {
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.question-box {
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  border-radius: 12px;
  transition: all 0.3s ease;
  font-size: 1.4rem;
  color: #b8b9c3;
  line-height: 1.6;
  padding: 1.5rem 2rem;  /* Added more padding */
  margin-bottom: 1rem;   /* Space between boxes */
  min-height: 40px;      /* Minimum height */
}

.question-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background-color: #3f4156;
  cursor: pointer;
}

.empty-conversation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px); /* Adjust based on your header/footer height */
  padding: 2rem;
}

.empty-conversation-content {
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.empty-conversation-content .description {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #b8b9c3;
}

.example-questions {
  margin: 0 auto;
  margin-top: 2rem;
}

/* Add these styles to handle modal dropdown overflow */
.modal {
  overflow: visible !important;
}

.modal-content {
  overflow: visible !important;
}

.custom-dropdown {
  position: relative;
}

/* Update/add these styles for the integrated search dropdown */
.custom-dropdown {
  position: relative;
}

.custom-dropdown .form-control {
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  color: #ffffff;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 10px;
  width: 100%;
}

.custom-dropdown .form-control:focus {
  background-color: #3f4156;
  border-color: #7c3aed;
  box-shadow: 0 0 0 0.2rem rgba(124, 58, 237, 0.25);
  outline: none;
}

.custom-dropdown .dropdown-menu {
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  border-radius: 10px;
  margin-top: 5px;
  z-index: 1060;
}

.custom-dropdown .career-dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.custom-dropdown .career-dropdown-item:hover,
.custom-dropdown .career-dropdown-item:focus,
.custom-dropdown .career-dropdown-item.active {
  background-color: #3f4156;
}

/* Add scrollbar styling for the dropdown */
.custom-dropdown .dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

.custom-dropdown .dropdown-menu::-webkit-scrollbar-track {
  background: #2f3142;
  border-radius: 10px;
}

.custom-dropdown .dropdown-menu::-webkit-scrollbar-thumb {
  background: #4a4b65;
  border-radius: 10px;
}

.custom-dropdown .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #5c5d7a;
}

/* Add these styles after existing styles */

/* Dashboard Item Styles */
.dashboard-genarator-card {
  background: #1a1b26;
  border: 1px solid #2f3142;
  border-radius: 12px;
  padding: 20px;
  transition: all 0.3s ease;
  display: block;
  margin-bottom: 15px;
  cursor: pointer;
}

.dashboard-genarator-card:hover {
  background: #2f3142;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.dashboard-genarator-card.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.dashboard-genarator-card .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.dashboard-genarator-card .left-align {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dashboard-genarator-card .img-bar {
  flex-shrink: 0;
}

.dashboard-genarator-card .feature-icon {
  border-radius: 10px;
}

.dashboard-genarator-card .content {
  flex-grow: 1;
}

.dashboard-genarator-card .title {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 0 0 5px 0;
}

.dashboard-genarator-card .description {
  font-size: 14px;
  color: #9899ac;
  margin: 10px 0;
  line-height: 1.4;
}

.dashboard-genarator-card .badge-pill {
  background: #7c3aed;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
}

.dashboard-genarator-card .arrow-icon {
  color: #7c3aed;
  transition: transform 0.3s ease;
}

.dashboard-genarator-card:hover .arrow-icon {
  transform: translateX(3px);
}

.dashboard-genarator-card .right-align {
  flex-shrink: 0;
}

/* Dark theme scrollbar for containers */
.rbt-default-sidebar-wrapper::-webkit-scrollbar {
  width: 8px;
}

.rbt-default-sidebar-wrapper::-webkit-scrollbar-track {
  background: #1a1b26;
}

.rbt-default-sidebar-wrapper::-webkit-scrollbar-thumb {
  background: #2f3142;
  border-radius: 4px;
}

.rbt-default-sidebar-wrapper::-webkit-scrollbar-thumb:hover {
  background: #4a4b65;
}

/* Post Creation Modal Styles */
.post-creation-modal {
  background-color: rgba(26, 27, 38, 0.95);
  border-radius: 16px;
  padding: 32px;
  max-width: 800px;
  width: 90%;
  margin: 40px auto;
}

.post-creation-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.post-creation-modal .modal-title {
  font-size: 2rem;
  color: #ffffff;
  margin: 0;
}

.post-creation-modal .close-button {
  background: none;
  border: none;
  color: #9899ac;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 8px;
}

.post-creation-modal .close-button:hover {
  color: #ffffff;
}

.post-creation-modal .form-group {
  margin-bottom: 24px;
}

.post-creation-modal .form-control {
  width: 100%;
  background-color: #2f3142;
  border: 2px solid #4a4b65;
  border-radius: 12px;
  padding: 16px;
  font-size: 2rem;
  color: #ffffff;
  transition: all 0.3s ease;
}

.post-creation-modal .form-control:focus {
  background-color: #3f4156;
  border-color: #7c3aed;
  box-shadow: 0 0 0 2px rgba(124, 58, 237, 0.25);
  outline: none;
}

.post-creation-modal .post-content-input {
  min-height: 200px;
  resize: vertical;
}

.post-creation-modal .create-post-btn {
  background-color: #7c3aed;
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
  display: inline-block;
}

.post-creation-modal .create-post-btn:hover {
  background-color: #6d28d9;
  transform: translateY(-1px);
}

.post-creation-modal .create-post-btn:active {
  transform: translateY(0);
}

/* Post Creation Modal Styles */
.post-creation-modal .form-control::placeholder {
  color: #9899ac;
  opacity: 0.7;
}

/* Reply Modal Styles */
.reply-modal .form-control::placeholder {
  color: #9899ac;
  opacity: 0.7;
}

/* Add these new styles */
.signup-area {
  padding: 0 20px; /* Add horizontal padding */
}

.sign-up-wrapper {
  max-width: 600px; /* Limit maximum width */
  margin: 0 auto; /* Center the wrapper */
}

.sign-up-box {
  width: 100%; /* Make box take full width of wrapper */
  padding: 20px; /* Add some internal padding */
}

.signup-form {
  width: 100%; /* Ensure form takes full width */
}

.input-section {
  width: 100%; /* Make input sections take full width */
  margin-bottom: 15px; /* Add some spacing between inputs */
}

.input-section input {
  width: 100%; /* Make inputs take full width */
  padding: 12px 40px; /* Adjust padding to accommodate icons */
}

.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.status-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge.success {
  background-color: #dcfce7;
  color: #166534;
}

.status-badge.pending {
  background-color: #991b1b;
  color: #fee2e2;
}

.notes-text {
  display: block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

small {
  color: #6b7280;
  font-size: 0.75rem;
}

.forum-container {
  width: 100%;
  padding: 0;  /* Remove any default padding */
}

.jureeltd-generartor-section {
  width: 100%;
  padding: 0;  /* Remove any default padding */
}

.table-responsive {
  width: 100%;
  margin: 0;
  padding: 0;
}

.table {
  width: 100%;
  min-width: 800px;  /* Ensures table doesn't get too cramped on smaller screens */
  margin: 0;
}

/* Optional: Adjust column widths */
.table th,
.table td {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.table th:nth-child(1), /* Institution */
.table td:nth-child(1) {
  width: 20%;
}

.table th:nth-child(2), /* Opportunity */
.table td:nth-child(2) {
  width: 20%;
}

.table th:nth-child(3), /* Type */
.table td:nth-child(3) {
  width: 10%;
}

.table th:nth-child(4), /* Applied */
.table td:nth-child(4),
.table th:nth-child(5), /* Interview */
.table td:nth-child(5),
.table th:nth-child(6), /* Offer */
.table td:nth-child(6) {
  width: 12%;
}

.table th:nth-child(7), /* Notes */
.table td:nth-child(7) {
  width: 14%;
}

/* Table Styles */
.table-responsive {
  overflow-x: auto;
  background-color: #1a1b26;  /* Match darker background */
  border-radius: 12px;
  padding: 1rem;
}

.table {
  width: 100%;
  min-width: 800px;
  border-collapse: separate;
  border-spacing: 0 0.5rem;  /* Add space between rows */
}

.table th {
  padding: 1.5rem 2rem;
  background-color: #2f3142;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #4a4b65;
}

.table td {
  padding: 1.5rem 2rem;
  background-color: #2f3142;  /* Match question-box background */
  color: #b8b9c3;      /* Match question-box text color */
  font-size: 1.4rem;
  line-height: 1.6;
  border: none;
}

.table tr {
  transition: all 0.3s ease;
}

.table tbody tr:hover td {
  background-color: #3f4156;  /* Match question-box hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Status Badge Styles */
.status-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.status-badge {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
}

.status-badge.success {
  background-color: #166534;
  color: #dcfce7;
}

/* Institution and Notes Styles */
.table td strong {
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 500
}

/* Edit Button Styles */
.btn-edit {
  background-color: #2f3142;
  color: #ffffff;
  border: 1px solid #4a4b65;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  transition: all 0.3s ease;
}

.btn-edit:hover {
  background-color: #3f4156;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Application Edit Modal Styles */
.application-edit-modal {
  background-color: #1a1b26;
  border-radius: 16px;
  padding: 32px;
}

.application-edit-modal .form-group {
  margin-bottom: 24px;
}

.application-edit-modal .form-label {
  color: #ffffff;
  font-size: 1.4rem;
  margin-bottom: 8px;
}

.application-edit-modal .form-control {
  background-color: #2f3142;
  border: 1px solid #4a4b65;
  color: #ffffff;
  font-size: 1.4rem;
  padding: 12px;
  border-radius: 8px;
}

.application-edit-modal .form-check {
  margin-bottom: 16px;
}

.application-edit-modal .form-check-label {
  color: #ffffff;
  font-size: 1.4rem;
  margin-left: 8px;
}

.application-edit-modal .form-check-input:checked {
  background-color: #7c3aed;
  border-color: #7c3aed;
}

/* Filter Section Styles */
.filter-pills {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  padding: 20px;
  background-color: #2f3142;
  border-radius: 12px;
  border: 1px solid #4a4b65;
}

/* React-Select Custom Styles */
.filter-select {
  flex: 1;
  min-width: 200px;
}

.filter-select .select__control {
  background-color: #1a1b26;
  border: 1px solid #4a4b65;
  border-radius: 10px;
  min-height: 45px;
}

.filter-select .select__control--is-focused {
  border-color: #7c3aed;
  box-shadow: 0 0 0 1px #7c3aed;
}

.filter-select .select__menu {
  background-color: #1a1b26;
  border: 1px solid #4a4b65;
  border-radius: 10px;
  margin-top: 8px;
}

.filter-select .select__option {
  background-color: #1a1b26;
  color: #ffffff;
  font-size: 14px;
  padding: 12px 16px;
}

.filter-select .select__option--is-focused {
  background-color: #2f3142;
}

.filter-select .select__option--is-selected {
  background-color: #7c3aed;
}

.filter-select .select__multi-value {
  background-color: #2f3142;
  border-radius: 6px;
}

.filter-select .select__multi-value__label {
  color: #ffffff;
  font-size: 14px;
}

.filter-select .select__multi-value__remove {
  color: #9899ac;
}

.filter-select .select__multi-value__remove:hover {
  background-color: #4a4b65;
  color: #ffffff;
}

.filter-select .select__placeholder,
.filter-select .select__single-value {
  color: #ffffff;
}

/* Toggle Button Style */
.filter-toggle-btn {
  background-color: #1a1b26;
  border: 1px solid #4a4b65;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-height: 45px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter-toggle-btn:hover {
  background-color: #2f3142;
  border-color: #7c3aed;
}

.filter-toggle-btn.active {
  background-color: #7c3aed;
  border-color: #7c3aed;
}

.filter-toggle-btn svg {
  width: 16px;
  height: 16px;
}